import React, { useState, useEffect } from 'react'
import { Link } from "gatsby"
import SEO from '../components/seo'
import Layout from '../components/layout'
import * as styles from './menu.module.css'
import infoImg from '../images/info.png'
import favYes from '../images/fav_yes.svg'
import favNo from '../images/fav_no.svg'
import like from '../images/like.png'
import dislike from '../images/dislike.png'

export default function Week({ pageContext }) {
  const currentDate = new Date();
  const tomorrowDate = new Date(currentDate);
  tomorrowDate.setDate(tomorrowDate.getDate() + 1)

  const today = `${currentDate.getDate()}_${currentDate.getMonth() + 1}_`;
  const tomorrow = `${tomorrowDate.getDate()}_${tomorrowDate.getMonth() + 1}_`;

  const pageWeekStart = new Date(pageContext.menu.Start);

  let pageWeekEnd = new Date(pageContext.menu.Start);
  pageWeekEnd.setDate(pageWeekEnd.getDate() + 7);
  const isCurrentWeek = (currentDate < pageWeekEnd && currentDate > pageWeekStart);

  // Toggle element visibility
  const toggle = elemId => {
    document.getElementById(elemId).classList.toggle(styles.isVisible);
  };

  const [ownRestaurant, setOwnRestaurant] = useState(false);
  const [rating, setRating] = useState([]);
  const isBrowser = typeof window !== 'undefined';

  // This runs when the page is loaded.
  useEffect(() => {
    try {
      if (isBrowser) {
        const adsbygoogle = window.adsbygoogle || [];
        adsbygoogle.push({});
        adsbygoogle.push({});
      }
    } catch (e) {
      console.error(e);
    }

    if (isCurrentWeek) {
      let cdElemToday = document.getElementById(today);
      let cdElemTomorrow = document.getElementById(tomorrow);

      if (cdElemToday) {
        cdElemToday.classList.add(styles.now);
        let adbanner = document.getElementById('adbanner');
        cdElemToday.after(adbanner);
        cdElemToday.scrollIntoView();
      }

      if (cdElemTomorrow) {
        let adbanner2 = document.getElementById('adbanner2');
        cdElemTomorrow.after(adbanner2);
      }
    } else {
      let adbanner = document.getElementById('adbanner');
      let firstDay = document.getElementsByClassName('dayitem_0');

      if (adbanner && firstDay.length > 0) {
        firstDay[0].after(adbanner);
      }

      let adbanner2 = document.getElementById('adbanner2');
      let secondDay = document.getElementsByClassName('dayitem_1');

      if (adbanner2 && secondDay.length > 0) {
        secondDay[0].after(adbanner2);
      }
    }
  }, [])

  useEffect(() => {
    let or = localStorage.getItem('ownRestaurant');
    setOwnRestaurant(or && or.split(';#').filter(o => o.split('|').includes(pageContext.restaurantId)).length > 0);
  }, [pageContext.restaurantId])

  const toggleOwnRestaurant = () => {
    let or = localStorage.getItem('ownRestaurant');

    let ownRestaurantName = pageContext.menu.RestaurantName === 'Koulut' ? `${pageContext.menu.CityName} - ${pageContext.menu.RestaurantName}` : pageContext.menu.RestaurantName;

    if (!or || (or && !or.includes(pageContext.restaurantId))) {
      addOwnRestaurant(pageContext.restaurantId, ownRestaurantName, or);
    } else {
      removeOwnRestaurant(pageContext.restaurantId, or);
    }
  }

  const addOwnRestaurant = (id, name, or) => {
    if (!or) {
      localStorage.setItem('ownRestaurant', `${id}|${name}`);
    } else {
      localStorage.setItem('ownRestaurant', `${or};#${id}|${name}`);
    }

    setOwnRestaurant(true);
  }

  const likeMenu = async (mealGuid, mealName, likeType) => {
    try {
      let apiUrl = window.location.href.indexOf('localhost') > -1
        ? 'http://localhost:7071/api'
        : window.location.href.indexOf('azurestaticapps') > -1 ?
          'https://apim-ruoka.azure-api.net/kouluruoka-api' : '/api';

      await window.fetch(apiUrl + '/like',
        {
          method: 'POST',
          headers: { 'Content-type': 'application/json' },
          body: JSON.stringify({ 'mealGuid': mealGuid, 'name': mealName, 'type': likeType, 'ratingHash': rating.find(r => r.mealGuid === mealGuid).ratingHash })
        }
      )

      if (typeof window !== 'undefined') window.location.reload();
    }
    catch (error) {
      console.log('Error liking meal')
    }
  }

  const currentWeekNumber = () => {
    var d = new Date();
    var dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    return Math.ceil((((d - yearStart) / 86400000) + 1) / 7)
  }

  useEffect(() => {
    async function fetchRatings() {
      for (const day of pageContext.menu.Days) {
        for (const meal of day.Meals) {
          let r = await getRating(meal.MealGuid);
          if (r) {
            setRating(currentRatings => [...currentRatings, { mealGuid: meal.MealGuid, ratingHash: r.RatingHash, likes: r.Likes, dislikes: r.Dislikes }]);
          }
        }
      }
    }

    fetchRatings();

    if (isBrowser) {
      setTimeout(() => {
        let btns = window.document.getElementsByClassName('ratingsButton');
        for (var i = 0; i < btns.length; i++) {
          btns[i].classList.remove(styles.disabled);
        }
      }, 2000)
    }

  }, [pageContext.menu.Days])

  const getRating = async (mealGuid) => {
    try {
      let apiUrl = window.location.href.indexOf('localhost') > -1
        ? 'http://localhost:7071/api'
        : window.location.href.indexOf('azurestaticapps') > -1 ?
          'https://apim-ruoka.azure-api.net/kouluruoka-api' : '/api';

      let resp = await window.fetch(apiUrl + '/likes/?id=' + mealGuid);

      return await resp.json();
    }
    catch (error) {
      console.log('Error fetching likes')
    }
  }

  const removeOwnRestaurant = (id, or) => {
    let rArr = or.split(';#').filter(e => !e.split('|').includes(`${id}`));
    localStorage.setItem('ownRestaurant', rArr.join(';#'));

    setOwnRestaurant(false);
  }

  const weekNavigator = (dir, ignore) => {
    if (ignore) return;
    window.location.href = `/menu/${pageContext.restaurantId}/${dir ? '2' : ''}`
  }

  const WeekListTop = <div className={`${styles.weekListTop} ${styles.weekList}`}>
    {pageContext.menu.Url ? <span className={styles.noPrint}>Hups!</span> : <><span className={styles.noPrint}>{isCurrentWeek ? 'Tämä' : 'Ensi'} viikko</span>
      <button className={`${isCurrentWeek ? styles.disabled : ''} ${styles.noPrint}`} onClick={() => weekNavigator(false, isCurrentWeek)}>&lt;&lt; Tämä viikko</button>
      <button className={`${!isCurrentWeek ? styles.disabled : ''} ${styles.noPrint}`} onClick={() => weekNavigator(true, !isCurrentWeek)}>Ensi viikko &gt;&gt;</button>
    </>
    }
  </div>

  const WeekListBottom = <div className={`${styles.weekListBottom} ${styles.weekList} ${styles.noPrint}`}>
    {!pageContext.menu.Url && <><button className={isCurrentWeek ? styles.disabled : ''} onClick={() => weekNavigator(false, isCurrentWeek)}>&lt;&lt; Tämä viikko</button>
      <span></span>
      <button className={!isCurrentWeek ? styles.disabled : ''} onClick={() => weekNavigator(true, !isCurrentWeek)}>Ensi viikko &gt;&gt;</button>
    </>
    }
  </div>

  const IsOwnRestaurant = <button onClick={toggleOwnRestaurant} className={styles.noPrint}><img src={favYes} alt="Poista oma ravintola" /></button>
  const NotOwnRestaurant = <button onClick={toggleOwnRestaurant} className={styles.noPrint}><img src={favNo} alt="Valitse omaksi ravintolaksi" /></button>

  const pageTitle = (pageContext.menu.RestaurantName.indexOf(`${pageContext.menu.CityName} - `) === 0 ? pageContext.menu.RestaurantName : `${pageContext.menu.CityName} - ${pageContext.menu.RestaurantName}`);

  return (
    <Layout>
      <div>
        <style>{`header a {
            height: 40px!important;
            font-size: 1.8rem!important;
            line-height: 40px!important
        }        
        header {
            height: 60px!important;
            padding: 10px!important
        }        
        #pageTitle {
            font-size: 2rem;
            border-bottom: solid 1px #10984B
        }        
        #pageTitle img {
            max-width: 32px
        }        
        .main {
            margin-top: 0
        }`}
        </style>

        <SEO
          title={`${pageTitle} - Ruokalista ${isCurrentWeek ? 'tällä' : 'ensi'} viikolla`}
          description={`Koulun aamupala, lounas, päivällinen tai välipala ${isCurrentWeek ? 'tällä' : 'ensi'} viikolla`}
        />

        <div className={styles.pageTitle}>
          <h1 id={'pageTitle'}>{pageTitle}
            {ownRestaurant
              ? IsOwnRestaurant
              : NotOwnRestaurant
            }
          </h1>
        </div>

        {WeekListTop}

        {pageContext.menu.Url ?
          <div>
            {
              pageContext.menu.Url === '#'
                ? <>Koulun ruokalistaa ei ole löytynyt mistään, ei sitten millään. <Link to='/palaute/'>Vinkkaa</Link> meille, jos tiedät missä se on.</>
                : <>Koulun ruokalista on saatavilla toistaiseksi ainoastaan <a href={pageContext.menu.Url} target="_blank" rel="noreferrer">täältä</a>.<br /><br /> Nyt on muuten viikko <strong>{
                  // output current week number
                  currentWeekNumber()
                }</strong> ja <strong>{
                  // output current day of week
                  new Date().toLocaleString('fi-FI', { weekday: 'long' })
                }</strong> 🤓</>
            }
            <br /><br /><br />
            <hr></hr>
            <div className={styles.pageTitle}>
              <h2>Haluatko kouluruoka.fi-mestariksi?</h2>
              <p>
                Haemme nyt <i>kouluruoka.fi-mestareita</i>. Mestarin tehtävänä on syöttää oman koulun ruokalista kouluruoka.fi-palveluun.
                Tämä onnistuu helposti hallintasivulta, johon kirjaudut sisään omilla tunnuksillasi.
                <br /><br />
                Kiitoksena työstäsi saat koulun päätyttyä keväällä 2025 kouluruoka.fi-mestari T-paidan ja työtodistuksen. Lisäksi saat halutessasi nimimerkkisi
                kouluruoka.fi:hin ylläpitämäsi ruokalistan sivulle.
                <br /><br />
                <b>Kiinnostuitko?</b> Lue lisää <Link to='/mestari/'>täältä</Link> tai ota yhteyttä osoitteeseen <a href="mailto:mestari@kouluruoka.fi">mestari@kouluruoka.fi</a>.
                <br /><br />
                <i>Mestarin tulee olla yli 13 vuotias. Tarvitset lisäksi luvan vanhemmiltasi jos olet alle 18 vuotias.</i>
              </p>
            </div>

            <div id="adbanner3" className={[styles.noPrint, styles.adBannerParent].join(' ')} key="adbanner3">
              <ins className={[styles.adBanner, 'adsbygoogle'].join(' ')}
                data-ad-client="ca-pub-2668838512868610"
                data-ad-slot="5745330552"
                data-ad-format="horizontal,auto"
                data-full-width-responsive="true"></ins>
            </div>
          </div>

          : (pageContext.menu.Days && pageContext.menu.Days.length > 0) ? pageContext.menu.Days.map((day, day_i) => (
            <div id={day.Date.replace(/\./ig, '_').split(' ')[1]} key={day.Date} className={`${styles.dayMenu} dayitem_${day_i}`}>
              <article>
                <h2>{day.Date}</h2>
                {
                  day.Meals.map((meal, meal_i) => {
                    return (
                      <div key={`mt_${day_i}${meal_i}`}>
                        <h3>{meal.MealType}
                          {
                            rating.length > 0 ?
                              <>
                                <button onClick={() => likeMenu(meal.MealGuid, meal.Name, 1)} className={[styles.like, styles.disabled, 'ratingsButton', styles.noPrint].join(' ')}>
                                  <img src={like} alt="Tykkään tästä ruoasta" /></button>
                                <span className={[styles.likeCount, styles.noPrint].join(' ')}>{rating?.find(r => r.mealGuid === meal.MealGuid)?.likes}</span>
                                <button onClick={() => likeMenu(meal.MealGuid, meal.Name, 0)} className={[styles.dislike, styles.disabled, 'ratingsButton', styles.noPrint].join(' ')}>
                                  <img src={dislike} alt="En tykkää tästä ruoasta" /></button>
                                <span className={[styles.likeCount, styles.noPrint].join(' ')}>{rating?.find(r => r.mealGuid === meal.MealGuid)?.dislikes}</span>
                              </>
                              : <></>
                          }
                        </h3>
                        <div>
                          <span dangerouslySetInnerHTML={{ __html: meal.Name.replace(/\*LBR\*/g, '<br/>') }}></span>
                          {
                            meal.Nutritions && meal.Nutritions.filter(n => n).length > 0 ?
                              <button onClick={() => toggle(`nb_${day_i}${meal_i}`)} className={styles.noPrint}>
                                <img src={infoImg} alt="Ravintotiedot" /></button>
                              : ''
                          }
                        </div>
                        {
                          meal.Nutritions && meal.Nutritions.filter(n => n).length > 0 ?
                            <div id={`nb_${day_i}${meal_i}`} className={`${styles.nutritions} ${styles.toggleContent}`}>
                              {
                                meal.Nutritions.map((nutr, nutr_i) => {
                                  return (nutr ?
                                    <div key={`nu_${day_i}${meal_i}${nutr_i}`}><h4>{nutr.Name}</h4>
                                      <h5>Ainesosat:</h5> <span dangerouslySetInnerHTML={{ __html: nutr.Label ? nutr.Label.replace(/\*SS\*/g, '<strong>').replace(/\*ES\*/g, '</strong>') : '' }}></span>
                                      {nutr.Items ?
                                        <><h5>Ravintosisältö (100 g:ssa)</h5>
                                          {nutr.Items.map((item, item_i) => (<span key={`nu_${day_i}${meal_i}${nutr_i}${item_i}`}>{item.Text}<br /></span>))}
                                        </>
                                        : ''}
                                    </div>
                                    : '')
                                })
                              }
                            </div>
                            : ''
                        }
                      </div>
                    )
                  })
                }
              </article>
            </div>
          )) :
            <div>
              Ei ruokalistaa tälle viikolle :(

              <div id="adbanner3" className={[styles.noPrint, styles.adBannerParent].join(' ')} key="adbanner3">
                <ins className={[styles.adBanner, 'adsbygoogle'].join(' ')}
                  data-ad-client="ca-pub-2668838512868610"
                  data-ad-slot="5745330552"
                  data-ad-format="horizontal,auto"
                  data-full-width-responsive="true"></ins>
              </div>
            </div>
        }

        {WeekListBottom}

        {!pageContext.menu.Url &&
          <div className={styles.abbreviations}>
            VL - Vähälaktoosinen, L - Laktoositon, M - Maidoton, G - Gluteeniton, K - Kasvis/maidoton/kananmunaton, S - Sianlihaton, N - Naudanlihaton, MU - Kananmunaton, VEG - Vegaani, */♥/SY - Sydänmerkki
          </div>
        }

        <div id="adbanner" className={[styles.noPrint, styles.adBannerParent].join(' ')} key="adbanner">
          <ins className={[styles.adBanner, 'adsbygoogle'].join(' ')}
            data-ad-client="ca-pub-2668838512868610"
            data-ad-slot="5745330552"
            data-ad-format="horizontal,auto"
            data-full-width-responsive="true"></ins>
        </div>
        <div id="adbanner2" className={[styles.noPrint, styles.adBannerParent].join(' ')} key="adbanner2">
          <ins className={[styles.adBanner, 'adsbygoogle'].join(' ')}
            data-ad-client="ca-pub-2668838512868610"
            data-ad-slot="5745330552"
            data-ad-format="horizontal,auto"
            data-full-width-responsive="true"></ins>
        </div>
      </div>
    </Layout>
  )
}