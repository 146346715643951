// extracted by mini-css-extract-plugin
export var abbreviations = "menu-module--abbreviations--AEvUc";
export var adBanner = "menu-module--adBanner--Vr1ob";
export var adBannerParent = "menu-module--adBannerParent--3F6Ip";
export var dayMenu = "menu-module--dayMenu--61C7l";
export var disabled = "menu-module--disabled--LjQJG";
export var dislike = "menu-module--dislike--tCbYT";
export var isVisible = "menu-module--isVisible--Ju1zZ";
export var like = "menu-module--like--QLenq";
export var likeCount = "menu-module--likeCount--wbhZM";
export var noPrint = "menu-module--noPrint--bsTI+";
export var now = "menu-module--now--Lcdyn";
export var nutritions = "menu-module--nutritions--NRBLS";
export var pageTitle = "menu-module--pageTitle--RSnnG";
export var toggleContent = "menu-module--toggleContent--DlLIA";
export var weekList = "menu-module--weekList--8s7kd";
export var weekListBottom = "menu-module--weekListBottom--wA-qf";
export var weekListTop = "menu-module--weekListTop--TncMp";